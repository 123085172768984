<template>
  <div class="pt-4">
    <v-row>
      <v-col cols="5">
        <v-autocomplete
          v-model="formData.mesaId"
          :items="mesas"
          hide-no-data
          hide-selected
          item-text="nome"
          item-value="id"
          placeholder="Selecione a mesa"
          outlined
          dense
          :hide-details="true"
          :clearable="true"
          class="mb-4"
          label="Mesa"
          small-chips
        ></v-autocomplete>
        <v-autocomplete
          v-model="permissao"
          :items="permissoes"
          hide-no-data
          hide-selected
          item-text="name"
          item-value="name"
          placeholder="Selecione a permissão"
          outlined
          dense
          :hide-details="true"
          :clearable="true"
          class="mb-4"
          label="Permissão"
          small-chips
          :return-object="true"
          :validate-on-blur="true"
        ></v-autocomplete>
        <v-switch
          v-model="formData.acessoApp"
          label="Acesso ao Sistema"
          inset
          :hide-details="true"
          class="mt-0 pa-0 mb-4"
          color="cyan"
        ></v-switch>
        <v-switch
          v-model="formData.acessoSistema"
          label="Acesso ao aplicativo"
          inset
          :hide-details="true"
          class="mt-0 pa-0 mb-4"
          color="cyan"
        ></v-switch>
        <v-btn
          depressed
          color="fmq_gray"
          dark
          @click="editarFuncionario"
          :loading="loading"
          >Editar</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "FuncionarioPermissoes",
  props: {
    info: {
      type: Object,
      required: true,
    },
    listaMesa: {
      type: Array,
      required: true,
    },
    listaPermissoes: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    permissoes: [],
    mesas: [],
    formData: {},
    permissao: {},
    mesa: {},
  }),
  created() {
    this.mesas = this.listaMesa;
    this.permissoes = this.listaPermissoes;
    this.formData = this.info;

    this.permissao = this.permissoes.find(
      ({ name }) => name === this.formData.user.tipo
    );

    if (!this.formData.mesaId) {
      this.mesas.push({
        id: null,
        nome: "Sem mesa associada",
      });
      this.formData.mesaId = null;
    }
  },
  methods: {
    editarFuncionario() {
      this.$emit("editarFuncionario", {
        permissao: this.permissao ? this.permissao.name : "null",
        mesa_id: this.formData.mesaId,
        acesso_sistema: this.formData.acessoSistema,
        acesso_app: this.formData.acessoApp,
      });
    },
  },
};
</script>

<style></style>
