<template>
  <div class="pt-4">
    <div class="mb-4">
      <span class="subtitle-2 font-weight-bold">Nome: </span>
      <span class="body-1 text--fmq_black">{{ info.nome }}</span>
    </div>
    <div class="mb-4">
      <span class="subtitle-2 font-weight-bold">Matrícula: </span>
      <span class="body-1 text--fmq_black">{{ info.matricula }}</span>
    </div>
    <div class="mb-4">
      <span class="subtitle-2 font-weight-bold">Email: </span>
      <span class="body-1 text--fmq_black">{{ info.email }}</span>
    </div>
    <div class="mb-4">
      <span class="subtitle-2 font-weight-bold">Telefone: </span>
      <span class="body-1 text--fmq_black">{{ info.telefone }}</span>
    </div>
    <div class="mb-4">
      <span class="subtitle-2 font-weight-bold">Rua: </span>
      <span class="body-1 text--fmq_black">{{ info.rua }}</span>
    </div>
    <div class="mb-4">
      <span class="subtitle-2 font-weight-bold">Número: </span>
      <span class="body-1 text--fmq_black">{{ info.numero }}</span>
    </div>
    <div class="mb-4">
      <span class="subtitle-2 font-weight-bold">Complemento: </span>
      <span class="body-1 text--fmq_black">{{ info.complemento }}</span>
    </div>
    <div class="mb-4">
      <span class="subtitle-2 font-weight-bold">Bairro: </span>
      <span class="body-1 text--fmq_black">{{ info.bairro }}</span>
    </div>
    <div class="mb-4">
      <span class="subtitle-2 font-weight-bold">Cidade: </span>
      <span class="body-1 text--fmq_black">{{ info.cidade }}</span>
    </div>
    <div class="mb-4">
      <span class="subtitle-2 font-weight-bold">Estado: </span>
      <span class="body-1 text--fmq_black">{{ info.estado }}</span>
    </div>
    <div class="mb-4">
      <span class="subtitle-2 font-weight-bold">CEP: </span>
      <span class="body-1 text--fmq_black">{{ info.cep }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "FuncionarioInfo",
  props: {
    info: {
      type: Object,
      required: true,
    },
  },
};
</script>
